import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
        overflowX: "scroll"
    },
    searchInput: {
        width: '90%'
    },
    right: {
        textAlign: 'right'
    }
}))