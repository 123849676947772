import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import SidebarItem from './SidebarItem';

const SidebarItemCollapse = ({ item, path, drawerOpen, textColor, firstLevel }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (item.title === "Orders") {
            setOpen(true);
        }
    }, [item.title])

    return (
        <ListItem
            disablePadding
            sx={{
                display: 'block',
                ml: drawerOpen && !firstLevel ? 2 : 0,
            }}
        >
            <ListItemButton
                onClick={() => { setOpen(!open) }}
                sx={{
                    minHeight: 48,
                    justifyContent: drawerOpen ? 'initial' : 'center',
                    pl: 2,
                    pr: 1
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: drawerOpen ? 2 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} sx={{ opacity: drawerOpen ? 1 : 0, color: textColor }} />
                {drawerOpen ? (open ? <ExpandLessIcon color='action' /> : <ExpandMoreIcon color='action' />) : null}
            </ListItemButton>
            <Collapse in={open} timeout='auto'>
                <List>
                    {item.children.map((item, index) => (
                        item.children ?
                            <SidebarItemCollapse key={index} item={item} path={path} drawerOpen={drawerOpen} textColor={textColor} firstLevel={false} /> :
                            <SidebarItem key={index} item={item} path={path} drawerOpen={drawerOpen} textColor={textColor} firstLevel={false} />
                    ))}
                </List>
            </Collapse>
        </ListItem>
    )
};

export default SidebarItemCollapse;