import React from 'react';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiPaginationItem-root': {
            minWidth: 18,
            padding: 0
        }
    }
}))

const AppPagination = ({ setPage, pageCount }) => {
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        // <div className={classes.container}>
        //     <div clasName = {classes.root}>
        <Box display="flex" justifyContent="center">
            <Pagination
                size="small"
                siblingCount={0}
                count={pageCount}
                onChange={handleChangePage}
                className={classes.root}
            />


        </Box>
        //     </div>

        // </div>
    )
}

export default AppPagination
