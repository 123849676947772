import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { useStyles as useLocalStyles } from './styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    right: {
        textAlign: 'right',
    },
    paper: {
        textAlign: 'left',
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        height: 260
    }
}));


const OrderDetail = ({ order }) => {
    const classes = useStyles();
    const localClasses = useLocalStyles();

    const DisplayAlertMessage = () => {
        if (!order.processed) {
            return (
                <Typography>
                    No information
                </Typography>
            )
        }
        if (order.fraud || order.flagType !== 0) {
            let alertReason = {};
            if (order.fraud) {
                alertReason['Possible Fraud'] = "Billing and Shipping Addrs Mismatch";
            }

            if (order.flagType !== 0) {
                switch (order.flagType) {
                    case 1: alertReason['Red Flag'] = "Email is Flagged"; break
                    case 2: alertReason['Red Flag'] = "Address is Flagged"; break;
                    case 3: alertReason['Red Flag'] = "IP is Flagged"; break;
                    case 4: alertReason['Red Flag'] = "Phone is Flagged"; break;
                    case 5: alertReason['Red Flag'] = "Shipping Address is missing"; break;
                    default: ;
                }
            }

            return (
                <>
                    {order.fraud &&
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography >
                                Possible Fraud:
                            </Typography>

                            <Typography sx={{ fontSize: 12 }}>
                                {alertReason['Possible Fraud']}
                            </Typography>
                        </Box>}
                    {order.fraud && order.flagType !== 0 && <br />}
                    {
                        order.flagType !== 0 &&
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography >
                                Red Flag:
                            </Typography>

                            <Typography sx={{ fontSize: 12 }}>
                                {alertReason['Red Flag']}
                            </Typography>
                        </Box>
                    }
                </>
            )
        } else return (
            <Typography>
                Cleared
            </Typography>
        )
    }

    return (
        <>
            <Grid item xs={12} my={2}>
                <Typography variant="h5" align="left">Order {order.id}</Typography>
            </Grid>
            <Grid container alignItems="stretch" justify='space-around' spacing={2}>
                <Grid item xs={12} md={6} lg={4} className={classes.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Summary
                        </Typography>
                        <br />
                        <DisplayAlertMessage />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={4} className={classes.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Location
                        </Typography>
                        <br />

                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <Typography sx={{ width: '50%' }}>
                                Billing Addr
                            </Typography>

                            <Typography sx={{ width: '50%' }}>
                                Shipping Addr
                            </Typography>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <Typography align="left" sx={{ fontSize: 12, width: '50%' }} >
                                {order.billing_address.first_name} {order.billing_address.last_name} {'\n'}
                                {order.billing_address.street_1} {'\n'}
                            </Typography>

                            <Typography align="left" sx={{ fontSize: 12, width: '50%' }} >
                                {order.shipping_address.first_name} {order.shipping_address.last_name} {'\n'}
                                {order.shipping_address.street_1} {'\n'}
                            </Typography>
                        </Box>

                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <Typography align="left" sx={{ fontSize: 12, width: '50%' }} >
                                {order.billing_address.city} {order.billing_address.state} {'\n'}
                                {order.billing_address.zip} {'\n'}
                                {order.billing_address.country}
                            </Typography>

                            <Typography align="left" sx={{ fontSize: 12, width: '50%' }} >
                                {order.shipping_address.city} {order.shipping_address.state} {'\n'}
                                {order.shipping_address.zip} {'\n'}
                                {order.shipping_address.country}
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={4} className={classes.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Order Information
                        </Typography>
                        <br />

                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', pr: 1 }}>
                            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }} className={localClasses.secondary}>
                                <Typography sx={{ fontSize: 12 }}>
                                    Total
                                </Typography >

                                <Typography sx={{ fontSize: 12 }}>
                                    Shipping Method
                                </Typography>
                            </Box>

                            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }} className={classes.right}>
                                <Typography sx={{ fontSize: 12 }}>
                                    {order.currency_symbol}{order.total}
                                </Typography>

                                <Typography sx={{ fontSize: 12 }}>
                                    {order.shipping_method}
                                </Typography>
                            </Box>

                        </Box>


                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', pr: 1 }}>
                            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }} className={localClasses.secondary}>
                                <Typography sx={{ fontSize: 12 }}>
                                    Shipping Fee
                                </Typography >
                            </Box>

                            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }} className={classes.right}>
                                <Typography sx={{ fontSize: 12 }}>
                                    {order.currency_symbol}{order.shipping_fee}
                                </Typography>
                            </Box>

                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', mb: 1 }} className={localClasses.secondary}>
                            <Typography sx={{ width: '38%', fontSize: 9 }}>
                                Order Date
                            </Typography>

                            <Typography sx={{ width: '38%', fontSize: 9 }}>
                                Time Stamp
                            </Typography>
                            <Typography sx={{ width: '24%', fontSize: 9 }}>
                                Time Zone
                            </Typography>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <Typography align="left" sx={{ fontSize: 12, width: '38%' }} >
                                {order.date}
                            </Typography>

                            <Typography align="left" sx={{ fontSize: 12, width: '38%' }} >
                                {order.timestamp}
                            </Typography>

                            <Typography align="left" sx={{ fontSize: 12, width: '24%' }} >
                                PST
                            </Typography>
                        </Box>

                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4} className={classes.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Device
                        </Typography>
                        <br />

                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            {
                                order.order_source === 'android' ?
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                        <PhoneAndroidOutlinedIcon fontSize="medium"
                                            sx={{
                                                padding: 0.4,
                                                color: "#ff8c1a",
                                                backgroundColor: "#ffd9b3",
                                                borderRadius: 12,
                                                marginRight: 1,
                                                marginTop: 0.5
                                            }} />
                                        <ListItemText>Android Order</ListItemText>
                                    </Box> :
                                    order.order_source === 'iphone' ?
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <PhoneIphoneOutlinedIcon fontSize="medium"
                                                sx={{
                                                    padding: 0.4,
                                                    color: "#ff8c1a",
                                                    backgroundColor: "#ffd9b3",
                                                    borderRadius: 12,
                                                    marginRight: 1,
                                                    marginTop: 0.5
                                                }} />
                                            <ListItemText>Iphone Order</ListItemText>
                                        </Box> :
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <ComputerOutlinedIcon fontSize="medium"
                                                sx={{
                                                    padding: 0.4,
                                                    color: "#ff8c1a",
                                                    backgroundColor: "#ffd9b3",
                                                    borderRadius: 12,
                                                    marginRight: 1,
                                                    marginTop: 0.5
                                                }} />
                                            <ListItemText>Internet Order</ListItemText>
                                        </Box>
                            }
                        </Box>
                        <br />
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <Typography align="left" sx={{ fontSize: 12, width: '50%' }} className={localClasses.secondary}>
                                IP address
                            </Typography>

                            <Typography align="left" sx={{ fontSize: 12, width: '50%' }} >
                                {order.ip_address}
                            </Typography>
                        </Box>

                    </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={4} className={classes.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Customer
                        </Typography>
                        <br />

                        <div>
                            <Typography
                                component="div">
                                {order.billing_address.first_name} {order.billing_address.last_name}</Typography>
                            <Typography
                                sx={{ fontSize: 12 }}
                                component="div"
                                className={localClasses.secondary}
                            >
                                {order.billing_address.city} {order.billing_address.state} {order.billing_address.country}</Typography>
                        </div>

                        <br />

                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography>
                                Contact
                            </Typography>

                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <LocalPhoneOutlinedIcon fontSize="medium" sx={{
                                    padding: 0.4,
                                    color: "#39e600",
                                    backgroundColor: "#d9ffcc",
                                    borderRadius: 12,
                                    marginRight: 1,
                                    marginTop: 0.2
                                }} />
                                <ListItemText primaryTypographyProps={{ fontSize: 12 }}>{order.billing_address.phone}</ListItemText>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <EmailOutlinedIcon fontSize="medium" sx={{
                                    padding: 0.4,
                                    color: "#0099ff",
                                    backgroundColor: "#ccebff",
                                    borderRadius: 12,
                                    marginRight: 1,
                                    marginTop: 0.2
                                }} />
                                <ListItemText primaryTypographyProps={{ fontSize: 12 }}>{order.billing_address.email}</ListItemText>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={4} className={classes.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Payment
                        </Typography>
                        <br />

                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', pr: 1 }}>
                            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }}>
                                <Typography >
                                    Payment Method
                                </Typography >

                                {order.credit_card_type &&
                                    <Typography>
                                        Credit Card Type
                                    </Typography>}
                            </Box>


                            <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column' }} className={classes.right}>
                                <Typography >
                                    {order.payment_method}
                                </Typography>

                                {order.credit_card_type &&
                                    <Typography>
                                        {order.credit_card_type}
                                    </Typography>}
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid >

        </>
    )
}

export default OrderDetail;
