import React, { useState, useEffect } from 'react';
import { InputAdornment, TextField, Button, Typography, Link, IconButton, Grid, MenuItem } from '@mui/material';
import { CanadaStates, USStates, ZipPatterns, Countries } from '../../data';
import { CountrySelect, PlatformSelect } from '../../components/common';
import { AuthService } from '../../services/AuthService';
import { makeStyles } from '@mui/styles';
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import displayError from '../../errors/displayError';

//contains at least one lower-case/upper-case/digit/special char
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{12,24}$/;


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    middle: {
        textAlign: 'center',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

function Register() {
    const classes = useStyles();
    const [company, setCompany] = useState({ storeName: '', website: '', platform: '' });
    const [contact, setContact] = useState({ firstName: '', lastName: '', title: '', email: '', phone: '' });
    const [addr, setAddr] = useState({ country: 'United States', street_1: '', city: '', state: '', zip: '' });

    const [country, setCountry] = useState(Countries[230]); // displayed in the input

    const [zipPattern, setZipPattern] = useState({ placeholder: 'xxxxx', pattern: "[0-9]{5}(-[0-9]{4})?" });
    const [zipDisplay, setZipDisplay] = useState({ disabled: false, backgroundColor: 'white' });
    const [states, setStates] = useState(USStates);
    const [isUSCanada, setIsUSCanada] = useState(true);

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [matchPassword, setMatchPassword] = useState('');
    const [validMatch, setValidMatch] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [eye, setEye] = useState(false);
    const [matchEye, setMatchEye] = useState(false);
    const handleEye = () => {
        setEye(!eye);
    }

    const handleMatchEye = () => {
        setMatchEye(!matchEye);
    }

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password));
        setValidMatch(password === matchPassword);
        setErrMsg('');
    }, [password, matchPassword]);

    const handlePlatformChange = (newPlatform) => {
        setCompany({ ...company, platform: newPlatform });
    }
    const handleCompanyChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setCompany({ ...company, [name]: value });
    };

    const handleContactChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setContact({ ...contact, [name]: value });
    };

    const handleAddrChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setAddr({ ...addr, [name]: value });
    };

    const handleCountryChange = (newCountry) => {
        const country = newCountry;
        setAddr({ ...addr, country });
        checkStateZipPatternChanges(country);
    };

    const checkStateZipPatternChanges = (country) => {
        if (country === 'United States') {
            setStates(USStates);
            setIsUSCanada(true);
        } else if (country === 'Canada') {
            setStates(CanadaStates);
            setIsUSCanada(true);
        } else {
            setIsUSCanada(false);
        }
        const zipCode = ZipPatterns.get(country);
        setZipPattern(zipCode);
        if (zipCode.pattern === '') {
            setZipDisplay({ disabled: true, backgroundColor: '#D3D3D3' });
        } else {
            setZipDisplay({ disabled: false, backgroundColor: 'white' });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // if button enabled with JS hack
        const v1 = PWD_REGEX.test(password);
        if (!v1) {
            setErrMsg("Invalid Pasword");
            return;
        } else if (password !== matchPassword) {
            setErrMsg("Password does not match");
            return;
        }

        AuthService.register(company, addr, contact, password)
            .then(response => {
                console.log(response.data);
                setSuccess(true);
                setPassword('');
                setMatchPassword('');
            })
            .catch(err => {
                displayError(err, setErrMsg);
            });
    };


    return (
        <>
            {
                success ? (
                    <>
                        <h1 className={"alert-success register-container"}>Success! Please check your email to verify account</h1>
                        <p>
                            <a href="/login">Sign In</a>
                        </p>
                    </>
                ) :
                    (
                        <>
                            <p className={errMsg ? "alert-danger register-container" : "offscreen"}>{errMsg}</p>
                            <h1>Register</h1>
                            <form className="register-container" onSubmit={(e) => handleSubmit(e)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} className={classes.middle}>
                                        <Typography>
                                            Already have an account?
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Link href="/login" sx={{
                                            display: 'inline-block',
                                            mx: 'auto',
                                            mt: 0.5,
                                            width: '90%',
                                            textDecoration: 'none'
                                        }}>Log in</Link>
                                    </Grid>

                                    {/* company information */}
                                    <Grid item xs={12}>
                                        <Typography sx={{ pl: 1, pt: 1, fontWeight: 550, fontSize: 14, textAlign: 'left' }}>
                                            Business Information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            type='text'
                                            label="Store Name"
                                            variant="outlined"
                                            size="small"
                                            name="storeName"
                                            value={company.storeName || ''}
                                            sx={{
                                                mx: 'auto',
                                                width: '100%'
                                            }}
                                            onChange={handleCompanyChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            type='text'
                                            label="Store Website Link"
                                            variant="outlined"
                                            size="small"
                                            name="website"
                                            value={company.website || ''}
                                            sx={{
                                                width: '100%',
                                            }}
                                            onChange={handleCompanyChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <PlatformSelect handlePlatformChange={handlePlatformChange} />
                                    </Grid>

                                    {/* company address */}
                                    <Grid item xs={6} >
                                        <CountrySelect country={country} setCountry={setCountry} handleCountryChange={handleCountryChange} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            type='text'
                                            label="Street Address"
                                            variant="outlined"
                                            size="small"
                                            name="street_1"
                                            value={addr.street_1 || ''}
                                            placeholder="Street Address or P.O. Box"
                                            onChange={handleAddrChange}
                                            sx={{
                                                width: '100%'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            type='text'
                                            label="City"
                                            variant="outlined"
                                            size="small"
                                            name="city"
                                            value={addr.city || ''}
                                            onChange={handleAddrChange}
                                            required
                                        />
                                    </Grid>
                                    {/* <InputLabel id="state-select-label">State</InputLabel> */}
                                    <Grid item xs={4} >
                                        {isUSCanada ?
                                            <TextField
                                                required
                                                label="State"
                                                variant="outlined"
                                                size="small"
                                                name="state"
                                                value={addr.state || ''}
                                                placeholder="State"
                                                onChange={handleAddrChange}
                                                select
                                                sx={{
                                                    width: '100%',
                                                }}
                                            >
                                                {
                                                    states.map((state, index) => {
                                                        return (<MenuItem key={index} value={state}>{state}</MenuItem>)
                                                    })
                                                }
                                            </TextField> :
                                            <TextField
                                                type='text'
                                                label="State"
                                                variant="outlined"
                                                size="small"
                                                name="state"
                                                value={addr.state || ''}
                                                placeholder="State"
                                                onChange={handleAddrChange}
                                                sx={{
                                                    width: '100%'
                                                }}
                                                required
                                            />
                                        }
                                    </Grid>

                                    <Grid item xs={4} >
                                        <TextField
                                            required
                                            type='text'
                                            label="Zip"
                                            disabled={zipDisplay.disabled}
                                            variant="outlined"
                                            size="small"
                                            name="zip"
                                            value={addr.zip || ''}
                                            placeholder={zipPattern.placeholder}
                                            onChange={handleAddrChange}
                                            inputProps={{ inputMode: 'numeric', pattern: zipPattern.pattern }}
                                            sx={{
                                                width: '100%',
                                                backgroundColor: zipDisplay.backgroundColor
                                            }}
                                        />
                                    </Grid>

                                    {/* contact information*/}
                                    <Grid item xs={12}>
                                        <Typography sx={{ pl: 1, pt: 1, fontWeight: 550, fontSize: 14, textAlign: 'left' }}>
                                            Personal Information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            required
                                            type='text'
                                            label="First name"
                                            variant="outlined"
                                            size="small"
                                            name='firstName'
                                            value={contact.firstName || ''}
                                            sx={{
                                                width: '100%',
                                            }}
                                            onChange={handleContactChange}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField
                                            required
                                            type='text'
                                            label="Last name"
                                            variant="outlined"
                                            size="small"
                                            name='lastName'
                                            value={contact.lastName || ''}
                                            sx={{
                                                width: '100%',
                                            }}
                                            onChange={handleContactChange}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField
                                            required
                                            type='text'
                                            label="Title"
                                            variant="outlined"
                                            size="small"
                                            name='title'
                                            value={contact.title || ''}
                                            sx={{
                                                width: '100%',
                                            }}
                                            onChange={handleContactChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            type='email'
                                            label="Email"
                                            variant="outlined"
                                            size="small"
                                            name="email"
                                            value={contact.email || ''}
                                            sx={{
                                                width: '100%',
                                            }}
                                            onChange={handleContactChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            type='text'
                                            label="Phone"
                                            variant="outlined"
                                            size="small"
                                            name="phone"
                                            value={contact.phone || ''}
                                            sx={{
                                                width: '100%',
                                            }}
                                            onChange={handleContactChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6} >
                                        <TextField
                                            required
                                            type={eye ? "text" : "password"}
                                            label="Password"
                                            variant="outlined"
                                            size="small"
                                            name="password"
                                            value={password || ''}
                                            sx={{
                                                width: '100%'
                                            }}
                                            onChange={(e) => setPassword(e.target.value)}
                                            error={!!password && !validPassword}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <FontAwesomeIcon icon={faCheck} className={validPassword ? "valid" : "hide"} />
                                                        <FontAwesomeIcon icon={faTimes} className={validPassword || !password ? "hide" : "invalid"} />
                                                        <IconButton onClick={handleEye}>
                                                            {eye ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6} >
                                        <TextField
                                            required
                                            type={matchEye ? "text" : "password"}
                                            label="Confirm Password"
                                            variant="outlined"
                                            size="small"
                                            name="matchPassword"
                                            value={matchPassword || ''}
                                            sx={{
                                                width: '100%'
                                            }}
                                            onChange={(e) => setMatchPassword(e.target.value)}
                                            error={!!matchPassword && !validMatch}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <FontAwesomeIcon icon={faCheck} className={validMatch && matchPassword ? "valid" : "hide"} />
                                                        <FontAwesomeIcon icon={faTimes} className={!validMatch && matchPassword ? "invalid" : "hide"} />
                                                        <IconButton onClick={handleMatchEye}>
                                                            {matchEye ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>

                                        <Typography
                                            className={password && !validPassword ? "instructions" : "offscreen"}
                                            align="left"
                                            sx={{
                                                mt: -0.3,
                                                fontWeight: 500,
                                                fontSize: 12,
                                                width: '100%'
                                            }}>
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                            12 to 24 characters.<br />
                                            Must include uppercase and lowercase letters, a number and a special character.<br />
                                            Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6} >

                                        <Typography
                                            className={matchPassword && !validMatch ? "instructions" : "offscreen"}
                                            align="left"
                                            sx={{
                                                mt: -0.3,
                                                fontWeight: 500,
                                                fontSize: 12,
                                                width: '100%'
                                            }}>
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                            Must match the first password input field.
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} className={classes.middle}>
                                        <Button
                                            type='submit'
                                            variant="contained"
                                            sx={{
                                                width: '40%'
                                            }}>
                                            Register
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </>)
            }
        </>
    );
}

export default Register;
