import React from 'react';
import OrderList from '../../components/orders/OrderList';
import OrderService from '../../services/OrderService';

const AllOrders = () => {
    return (
        <OrderList ordersFunct={OrderService.getOrders} />
    );
}

export default AllOrders;