import useAuth from './useAppContext';
import axios from '../axios';

// Two purposes
// 1, get the access token automatically when one tab is logged in; save repetitive logins
// 2, renew the access token when the access token expired
const useRefreshTokenLikeLogin = () => {
    const { setAuth } = useAuth();

    const refreshLikeLogin = async () => {
        const response = await axios.get('/api/v1/auth/refresh_like_login', {
            withCredentials: true
        });

        // Following prevents dead loop of infinite refresh in useRefreshToken
        // set both user and accessToken
        setAuth({ ...(response.data), storeId: response.data.user.storeId });
    }
    return refreshLikeLogin;
}

export default useRefreshTokenLikeLogin;
