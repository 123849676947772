import React, { useState, useEffect, useCallback } from 'react';
import { Plans, SubscribeContainer } from '../../components/stripe';
import { useLocation, useNavigate } from 'react-router-dom';
import { BillingService } from '../../services/BillingService';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Notification } from '../../components/common';
import notifyError from '../../errors/notifyError';
import { Box, Typography, Paper, Divider, CircularProgress } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import useAuth from '../../hooks/useAppContext';

const useStyles = makeStyles(theme => ({
    paper: {
        fontWeight: 'bold',
        padding: theme.spacing(4),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    }
}));

const CheckoutForm = () => {
    const classes = useStyles();
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const navigate = useNavigate();
    const [prices, setPrices] = useState([]);
    const [pricesAnnual, setPricesAnnual] = useState([]);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
    const [currentPrice, setCurrentPrice] = useState(null);
    const [buttonText, setText] = useState('subscribe');

    const [currentPlan, setCurrentPlan] = useState("Free");
    const [plan, setPlan] = useState("Free"); // selected Plan
    const [price, setPrice] = useState(null);
    const [priceAnnual, setPriceAnnual] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [interval, setInterval] = useState('month');
    const { auth } = useAuth();

    const StyledTypography = styled(Typography)(({ theme }) => ({
        fontSize: 30,
    }))

    useEffect(() => {
        if (!auth?.storeId) {
            setNotify({
                isOpen: true,
                message: 'Please Select a Store',
                type: 'error'
            })
            return;
        }
        let isMounted = true;
        const controller = new AbortController();
        BillingService.getPlansAndDefaultPayment(axiosPrivate)
            .then(response => {
                const { prices, pricesAnnual, defaultPaymentMethod, plan, currentPrice } = response.data;
                isMounted && setPrices(prices);
                isMounted && setPricesAnnual(pricesAnnual);
                isMounted && setDefaultPaymentMethod(defaultPaymentMethod);
                if (plan) {
                    isMounted && setInterval(currentPrice.interval);
                    isMounted && setCurrentPlan(plan);
                    isMounted && setCurrentPrice({ ...currentPrice, plan });
                    isMounted && setPlan(plan);

                    const price = prices.find(price => price.lookup_key === plan);
                    const priceAnnual = pricesAnnual.find(price => price.lookup_key === plan + "_annual");
                    isMounted && setPrice(price);
                    isMounted && setPriceAnnual(priceAnnual);
                }
                isMounted && setIsLoading(false);
            })
            .catch(err => {
                isMounted && setIsLoading(false);
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, navigate, location, auth]);


    const updateButtonText = useCallback((price, priceAnnual) => { // on interval change of unchanged same plan
        if (interval === 'month') {
            if (price.id === currentPrice.id) {
                setText("Purchase");
            } else {
                setText("Change"); // change interval
            }
        } else {
            if (priceAnnual.id === currentPrice.id) {
                setText("Purchase");
            } else {
                setText("Change");
            }
        }
    }, [currentPrice, interval]);

    const updateText = useCallback((price, priceAnnual) => { // on interval change of unchanged same plan
        if (!currentPrice)
            return;
        if (price.metadata.plan === currentPrice.plan) {
            updateButtonText(price, priceAnnual);
        }
    }, [currentPrice, updateButtonText]);

    const handleOtherClick = useCallback((e, newPlan) => {
        setPlan(newPlan);
        setPrice(null);
        setPriceAnnual(null);
    }, []);

    const handleClick = useCallback((e, newPrice) => {
        setPlan(newPrice.metadata.plan);
        setPrice({ ...newPrice });
        const priceAnnual = pricesAnnual.find(price => price.lookup_key === newPrice.lookup_key + "_annual");
        setPriceAnnual(priceAnnual);

        if (currentPrice === null) {
            setText("Subscribe");
            return;
        }

        if (newPrice.metadata.tier > currentPrice.tier) {
            setText("Upgrade");
        } else if (newPrice.metadata.tier < currentPrice.tier) {
            setText("Downgrade");
        } else {
            updateButtonText(newPrice, priceAnnual);
        }
    }, [currentPrice, pricesAnnual, updateButtonText]);

    return (
        <>
            {isLoading ?
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <CircularProgress sx={{
                        marginTop: 10,
                        marginBottom: 10,
                        mx: 'auto'
                    }} />
                </Box> :

                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "scroll",
                    // justifyContent: "flex-end" //DO NOT USE THIS WITH 'scroll'
                }}>
                    <Plans
                        prices={prices}
                        pricesAnnual={pricesAnnual}
                        plan={plan}
                        currentPlan={currentPlan}
                        handleOtherClick={handleOtherClick}
                        handleClick={handleClick}
                    />

                    <Divider sx={{ my: 2 }} />

                    {price ?
                        <SubscribeContainer
                            price={price}
                            priceAnnual={priceAnnual}
                            interval={interval}
                            setInterval={setInterval}
                            defaultPaymentMethod={defaultPaymentMethod}
                            buttonText={buttonText}
                            updateButtonText={updateText}
                        />
                        :
                        <Paper className={classes.paper}>
                            {plan === 'Free' ?
                                plan === currentPlan ?
                                    <StyledTypography>
                                        You are already enjoying {currentPlan} plan!
                                    </StyledTypography> :
                                    <StyledTypography>
                                        Free plan screens up to 100 orders for free
                                    </StyledTypography>
                                :
                                <div>
                                    <h4>Contact</h4>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }} className="contact">
                                        <EmailOutlinedIcon fontSize="small" sx={{
                                            color: "white",
                                            display: 'inline-block',
                                            marginRight: 1,
                                            marginTop: 0.9
                                        }} />
                                        <ListItemText primaryTypographyProps={{ fontSize: 15 }}>support@spotfraud.app</ListItemText>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row' }} className="contact">
                                        <LocalPhoneOutlinedIcon fontSize="small" sx={{
                                            color: "white",
                                            display: 'inline-block',
                                            marginRight: 1,
                                            marginTop: 0.9
                                        }} />
                                        <ListItemText primaryTypographyProps={{ fontSize: 15 }}>832.663.6568</ListItemText>
                                    </Box>
                                </div>
                            }
                        </Paper>
                    }

                    <Notification
                        notify={notify}
                        setNotify={setNotify}
                    />
                </Box>
            }
        </>
    )
}

export default CheckoutForm;
