import React from 'react';
import { useNavigate } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Paper, Typography, Divider, Grid } from '@mui/material';
import { Button } from "../common/inputs";
import { useStyles } from './styles';
import { capitalize } from '../../utils/string';
import useAuth from '../../hooks/useAppContext';
import clsx from 'clsx';

const CurrentPlan = ({ price, subscriptionId, pendingEvent }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { auth } = useAuth();

    const handleUpdatePlan = () => {
        navigate(`/settings/subscribe`, { replace: true })
    }

    const handleCancelPlan = () => {
        navigate('/settings/cancel', { state: { subscriptionId }, replace: true });
    }

    return (
        <Paper className={classes.pageContent}>
            <Grid container className={classes.gridInsidePaper}>
                <Grid item xs={6} lg={8}>
                    <Typography variant="h5" className={classes.left}>
                        Current Plan
                    </Typography>
                </Grid>
                <Grid item xs={subscriptionId && auth.user.role === 'admin_user' ? 3 : 6}
                    lg={subscriptionId && auth.user.role === 'admin_user' ? 2 : 4} className={classes.right}>
                    <Button
                        text="Update Plan"
                        color="grey"
                        size="medium"
                        onClick={handleUpdatePlan} />
                </Grid>
                {subscriptionId && auth.user.role === 'admin_user' &&
                    <Grid item xs={3} lg={2} className={classes.right}>

                        <Button
                            text="Cancel Plan"
                            color="grey"
                            size="medium"
                            onClick={handleCancelPlan} />
                    </Grid>
                }
            </Grid>
            <Divider />


            <Grid container item xs={12} className={clsx(classes.gridInsidePaper, classes.left)}>
                <Grid item xs={2} lg={1} >
                    <ReceiptIcon />
                </Grid>
                <Grid item xs={4} lg={4} className={classes.secondary}>
                    <Typography>
                        Current Plan
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={7}>
                    {price.plan} {pendingEvent && pendingEvent.type !== 'na' && `(${pendingEvent.type} on ${pendingEvent.date})`}
                </Grid>

                <Grid item xs={12} sx={{ my: 2 }}>
                    <Divider />
                </Grid>

                <Grid item xs={2} lg={1} >
                    <AttachMoneyIcon />
                </Grid>

                {price.plan === 'Free' ?
                    <>
                        <Grid item xs={4} lg={4} className={classes.secondary}>
                            <Typography>
                                Price
                            </Typography>
                        </Grid>
                        <Grid item xs={6} lg={7}>
                            <Typography>
                                $0.00
                            </Typography>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={4} lg={4} className={classes.secondary}>
                            <Typography>
                                Price ({capitalize(price.interval)})
                            </Typography>
                        </Grid>
                        <Grid item xs={6} lg={7}>
                            ${price.amount}
                        </Grid>
                    </>}
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Divider />
                </Grid>
            </Grid>
        </Paper >
    );
}

export default CurrentPlan;
