import React from 'react';
import OrderList from '../../components/orders/OrderList';
import OrderService from '../../services/OrderService';

const FlaggedOrders = () => {
    return (
        <OrderList ordersFunct={OrderService.getFlaggedOrders} />
    );
}

export default FlaggedOrders;