import React from 'react';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BusinessIcon from '@mui/icons-material/Business';
import { Paper, Typography, Divider, Grid } from '@mui/material';
import { Button } from "../common/inputs";
import { useStyles } from './styles';
import clsx from 'clsx';

const BillingDetails = ({ defaultPaymentMethod }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleManagePayment = () => {
        navigate('/settings/payment_methods', { replace: true });
    }

    return (
        <Paper className={classes.pageContent}>
            <Grid container className={classes.gridInsidePaper}>
                <Grid item xs={8} >
                    <Typography variant="h5" className={classes.left}>
                        Billing Details
                    </Typography>
                </Grid>
                <Grid item xs={4} className={classes.right}>
                    <Button
                        text="Manage Payment"
                        color="grey"
                        size="medium"
                        onClick={handleManagePayment} />
                </Grid>
            </Grid>
            <Divider />

            <Grid container className={clsx(classes.gridInsidePaper, classes.left)}>
                <Grid item xs={2} lg={1} >
                    <EmailIcon />
                </Grid>
                <Grid item xs={4} lg={4} className={classes.secondary}>
                    <Typography>
                        Billing Email
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={7}>
                    {defaultPaymentMethod.billing_details.email}
                </Grid>

                <Grid item xs={12} sx={{ my: 2 }}>
                    <Divider />
                </Grid>

                <Grid item xs={2} lg={1} >
                    <PersonIcon />
                </Grid>
                <Grid item xs={4} lg={4} className={classes.secondary}>
                    <Typography>
                        Card Holder Name
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={7}>
                    {defaultPaymentMethod.billing_details.name}
                </Grid>

                <Grid item xs={12} sx={{ my: 2 }}>
                    <Divider />
                </Grid>

                <Grid item xs={2} lg={1} >
                    <CreditCardIcon />
                </Grid>
                <Grid item xs={4} lg={4} className={classes.secondary}>
                    <Typography>
                        Card Number (Last 4)
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={7}>
                    {defaultPaymentMethod.card.last4}
                </Grid>

                <Grid item xs={12} sx={{ my: 2 }}>
                    <Divider />
                </Grid>


                <Grid item xs={2} lg={1} >
                    <CalendarMonthIcon />
                </Grid>
                <Grid item xs={4} lg={4} className={classes.secondary}>
                    <Typography>
                        Expiry (Month / Year)
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={7}>
                    {defaultPaymentMethod.card.exp_month} {'/'} {defaultPaymentMethod.card.exp_year}
                </Grid>

                <Grid item xs={12} sx={{ my: 2 }}>
                    <Divider />
                </Grid>

                <Grid item xs={2} lg={1} >
                    <BusinessIcon />
                </Grid>
                <Grid item xs={4} lg={4} className={classes.secondary}>
                    <Typography>
                        Address
                    </Typography>
                </Grid>
                <Grid item xs={6} lg={7}>
                    {defaultPaymentMethod.billing_details.address.line1} {', '}
                    {defaultPaymentMethod.billing_details.address.city} {' '}
                    {defaultPaymentMethod.billing_details.address.state} {' '}
                    {defaultPaymentMethod.billing_details.address.postal_code}
                </Grid>


                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Divider />
                </Grid>
            </Grid>


        </Paper >
    );
}

export default BillingDetails;
