import React from 'react';
import Typography from '@mui/material/Typography';
import FlagIcon from '@mui/icons-material/Flag';
import { useStyles } from './styles';

export function DisplayResult({ order }) {
    const classes = useStyles();
    if (!order.processed) {
        return <Typography variant="button" className={`${classes.base} ${classes.unprocessed}`}
            sx={{ textTransform: 'capitalize', fontSize: 13 }}
        >
            Inspecting
        </Typography>
    }
    if (order.fraud || order.flagType !== 0) {
        return <>
            {order.fraud && <Typography variant="button" className={`${classes.base} ${classes.fraud}`}
                sx={{ textTransform: 'capitalize', fontSize: 13 }}
            >
                Possible Fraud
            </Typography>
            }

            {order.flagType !== 0 && <FlagIcon fontSize="medium" className={classes.flagged} />}
        </>
    } else {
        return <Typography variant="button" className={`${classes.base} ${classes.cleared}`}
            sx={{ textTransform: 'capitalize', fontSize: 13 }} //the textTransform and fontSize properties only change via sx setting, instead of classes
        >
            Cleared
        </Typography >
    }
}