import React, { useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import menu from './Menu';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import SearchBar from '../orders/SearchBar';
import { grey } from "@mui/material/colors";
import Logo from '../Logo';

import { Drawer, DrawerHeader, AppBar } from './Drawer';

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(window.innerWidth > 480);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  const path = location.pathname;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}
        sx={{
          borderBottom: 3,
          borderColor: '#00e6e6',
        }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <SearchBar />

        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: grey[200],
            color: "black"
          }
        }}>
        <DrawerHeader>
          {/* <Typography variant="h6" noWrap component="div">
            Spot Fraud
          </Typography> */}
          <Logo />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menu.map((item, index) => (
            item.children ?
              <SidebarItemCollapse key={index} item={item} path={path} drawerOpen={open} textColor={'black'} firstLevel={true} /> :
              <SidebarItem key={index} item={item} path={path} drawerOpen={open} textColor={'black'} firstLevel={true} />
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{
        flexGrow: 1,
        width: '100%',
        backgroundColor: grey[100],
        overflow: "hidden",
        overflowY: "scroll"
      }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box >
  );
}