import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Countries } from '../../data';

export default function CountrySelect({ country, setCountry, handleCountryChange }) {

  return (
    <Autocomplete
      sx={{ width: 300 }}
      options={Countries}
      autoHighlight
      disableClearable
      getOptionLabel={(option) => option.label}
      size="small"
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      value={country}
      onChange={(event, newCountry) => {
        setCountry(newCountry);
        handleCountryChange(newCountry.label);
      }}
      renderInput={(params) => (
        <TextField
          size="small"
          required
          {...params}
          label="Choose a country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

