const setLogin = () => {
    localStorage.setItem('login', JSON.stringify(true));
}

const isLogin = () => {
    return JSON.parse(localStorage.getItem('login')) === true;
}


const setQueryIdResult = (id, orders) => {
    localStorage.setItem('id', JSON.stringify(id)); //the id query that just has executed
    localStorage.setItem('id_orders', JSON.stringify(orders)); //the orders for the executed id query
}

const getQueryId = () => {
    return JSON.parse(localStorage.getItem('id'));
}

const getQueryIdOrders = () => {
    const requestOrders = JSON.parse(localStorage.getItem('id_orders'));
    return requestOrders;
}

const setQueryEmailResult = (email, page, pageSize, count, orders) => {
    localStorage.setItem('email', JSON.stringify(email)); //the email query that just has executed
    localStorage.setItem('email_page', JSON.stringify(page)); //the page No.
    localStorage.setItem('email_page_size', JSON.stringify(pageSize)); //the page Size.
    //the orders for the executed email query in current page and pageSize
    localStorage.setItem('email_orders', JSON.stringify(orders));
    localStorage.setItem('email_orders_count', JSON.stringify(count)); //the total number of orders
}

const getQueryEmail = () => {
    return JSON.parse(localStorage.getItem('email'));
}

const getQueryEmailPage = () => {
    return JSON.parse(localStorage.getItem('email_page'));
}

const getQueryEmailPageSize = () => {
    return JSON.parse(localStorage.getItem('email_page_size'));
}

const getQueryEmailOrders = () => {
    return JSON.parse(localStorage.getItem('email_orders'));
}

const getQueryEmailOrderCount = () => {
    return JSON.parse(localStorage.getItem('email_orders_count'));
}

const setQueryZipResult = (zip, page, pageSize, count, orders) => {
    localStorage.setItem('zip', JSON.stringify(zip)); //the zip query that just has executed
    localStorage.setItem('zip_page', JSON.stringify(page)); //the page No.
    localStorage.setItem('zip_page_size', JSON.stringify(pageSize)); //the page size
    //the orders for the executed zip query in current page and pageSize
    localStorage.setItem('zip_orders', JSON.stringify(orders));
    localStorage.setItem('zip_orders_count', JSON.stringify(count)); //the total number of orders
}

const getQueryZip = () => {
    return JSON.parse(localStorage.getItem('zip'));
}

const getQueryZipPage = () => {
    return JSON.parse(localStorage.getItem('zip_page'));
}

const getQueryZipPageSize = () => {
    return JSON.parse(localStorage.getItem('zip_page_size'));
}

const getQueryZipOrders = () => {
    const requestOrders = JSON.parse(localStorage.getItem('zip_orders'));
    return requestOrders;
}

const getQueryZipOrderCount = () => {
    return JSON.parse(localStorage.getItem('zip_orders_count'));
}

const clearLogin = () => {
    localStorage.removeItem('login');
}

const clearSearch = () => {
    localStorage.removeItem('id');
    localStorage.removeItem('id_page');
    localStorage.removeItem('id_orders');
    localStorage.removeItem('email');
    localStorage.removeItem('email_page');
    localStorage.removeItem('email_page_size');
    localStorage.removeItem('email_orders');
    localStorage.removeItem('email_orders_count');
    localStorage.removeItem('zip');
    localStorage.removeItem('zip_page');
    localStorage.removeItem('zip_page_size');
    localStorage.removeItem('zip_orders');
    localStorage.removeItem('zip_orders_count');
}

const clearAll = () => {
    localStorage.clear();
}

export {
    setLogin,
    isLogin,
    clearLogin,
    setQueryIdResult,
    getQueryId,
    getQueryIdOrders,
    setQueryEmailResult,
    getQueryEmail,
    getQueryEmailPage,
    getQueryEmailPageSize,
    getQueryEmailOrders,
    getQueryEmailOrderCount,
    setQueryZipResult,
    getQueryZip,
    getQueryZipPage,
    getQueryZipPageSize,
    getQueryZipOrders,
    getQueryZipOrderCount,
    clearSearch,
    clearAll
}