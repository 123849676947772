import React, { useState, useEffect } from 'react';
import UserService from "../services/UserService";

import { PageHeader, Notification } from '../components/common';
import notifyError from '../errors/notifyError';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Paper, Grid, TextField, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import useAuth from '../hooks/useAppContext';
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import { InputAdornment } from '@mui/material';
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{12,24}$/;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    pageContent: {
        margin: theme.spacing(8),
        padding: theme.spacing(3),
        textAlign: 'left',
        width: 800
    },
}));

const Profile = () => {
    const classes = useStyles();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();
    const [errMsg, setErrMsg] = useState('');

    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);
    const [matchPassword, setMatchPassword] = useState('');
    const [validMatch, setValidMatch] = useState(false);

    const [oldEye, setOldEye] = useState(false);
    const [eye, setEye] = useState(false);
    const [matchEye, setMatchEye] = useState(false);

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });

    const handleOldEye = () => {
        setOldEye(!oldEye);
    }

    const handleEye = () => {
        setEye(!eye);
    }

    const handleMatchEye = () => {
        setMatchEye(!matchEye);
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        UserService.showMe(axiosPrivate)
            .then(response => {
                isMounted && setName(response.data.userName);
                isMounted && setEmail(response.data.email);
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, setName, setEmail]);

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password));
        setValidMatch(password === matchPassword);
        setErrMsg('');
    }, [password, matchPassword]);

    const handleChange = (e) => {
        const value = e.target.value;
        setName(value);
    };

    const handleNameChange = async (e) => {
        e.preventDefault();
        if (name) {
            try {
                console.log(name);
                const { data } = await UserService.updateUserName(axiosPrivate, name);
                setNotify({
                    isOpen: true,
                    message: 'Submitted Successfully',
                    type: 'success'
                })
            } catch (err) {
                notifyError(err, setNotify, navigate, location);
            }
        } else {
            setNotify({
                isOpen: true,
                message: 'No Name Value',
                type: 'error'
            })
        }
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();

        // if button enabled with JS hack
        const v1 = PWD_REGEX.test(password);
        if (!v1) {
            setErrMsg("Invalid Password");
            return;
        } else if (password !== matchPassword) {
            setErrMsg("Password does not match");
            return;
        }

        UserService.updatePassword(axiosPrivate, oldPassword, password)
            .then(response => {
                console.log(response.data);
                setPassword('');
                setMatchPassword('');
                setNotify({
                    isOpen: true,
                    message: 'Submitted Successfully',
                    type: 'success'
                })
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });
    };


    return (<>
        <PageHeader
            title="Profile"
        />
        <Paper className={classes.pageContent}>
            <p className={errMsg ? "alert-danger register-container" : "offscreen"}>{errMsg}</p>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        disabled
                        type='text'
                        label="Login email"
                        variant="outlined"
                        size="small"
                        name='email'
                        value={email || ''}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled
                        type='text'
                        label="Role"
                        variant="outlined"
                        size="small"
                        name='email'
                        value={auth.user.role || ''}
                    />
                </Grid>
            </Grid>
            <br />
            <form onSubmit={handleNameChange}>
                <Box sx={{
                    mt: 2,
                    pt: 2,
                    display: "flex",
                    flexDirection: "row",
                    overflow: "hidden"
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}>

                    <TextField
                        type='text'
                        label="Name"
                        variant="outlined"
                        size="small"
                        name="name"
                        value={name || ''}
                        onChange={handleChange}
                    />

                    <Button type='submit'
                        variant="contained"
                        onClick={handleNameChange}
                        sx={{
                            ml: 5,
                            width: '20%'
                        }}>Change</Button>
                </Box>

            </form>

            <br />
            <form onSubmit={handleChangePassword}>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TextField
                            required
                            align="left"
                            type={oldEye ? "text" : "password"}
                            label="Old Password"
                            variant="outlined"
                            size="small"
                            name="oldPassword"
                            value={oldPassword || ''}
                            onChange={(e) => setOldPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleOldEye}>
                                            {oldEye ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item xs={6} >
                        <TextField
                            required
                            align="left"
                            type={eye ? "text" : "password"}
                            label="New Password"
                            variant="outlined"
                            size="small"
                            name="password"
                            value={password || ''}
                            onChange={(e) => setPassword(e.target.value)}
                            error={!!password && !validPassword}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <FontAwesomeIcon icon={faCheck} className={validPassword ? "valid" : "hide"} />
                                        <FontAwesomeIcon icon={faTimes} className={validPassword || !password ? "hide" : "invalid"} />
                                        <IconButton onClick={handleEye}>
                                            {eye ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>

                        <Typography
                            className={password && !validPassword ? "instructions" : "offscreen"}
                            sx={{
                                fontWeight: 500,
                                fontSize: 12,
                            }}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            12 to 24 characters. <br />
                            Must include uppercase and lowercase letters, a number and a special character.<br />
                            Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                        </Typography>
                    </Grid>

                    <Grid item xs={6} >
                        <TextField
                            required
                            type={matchEye ? "text" : "password"}
                            label="Confirm Password"
                            variant="outlined"
                            size="small"
                            name="matchPassword"
                            value={matchPassword || ''}
                            onChange={(e) => setMatchPassword(e.target.value)}
                            error={!!matchPassword && !validMatch}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <FontAwesomeIcon icon={faCheck} className={validMatch && matchPassword ? "valid" : "hide"} />
                                        <FontAwesomeIcon icon={faTimes} className={!validMatch && matchPassword ? "invalid" : "hide"} />
                                        <IconButton onClick={handleMatchEye}>
                                            {matchEye ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                    </Grid>

                    <Grid item xs={6} >
                        <Typography
                            className={matchPassword && !validMatch ? "instructions" : "offscreen"}
                            sx={{
                                fontWeight: 500,
                                fontSize: 12
                            }}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            Must match the first password input field.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            type='submit'
                            variant="contained"
                            disabled={!oldPassword || !password || !matchPassword}
                            sx={{
                                width: '40%',
                            }}>
                            Change Password
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
        <Notification
            notify={notify}
            setNotify={setNotify}
        />
    </>);
};



export default Profile;
