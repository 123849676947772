import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
    pageContent: {
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
        padding: theme.spacing(3),
        overflowX: "scroll"
    },
    gridInsidePaper: {
        padding: theme.spacing(2),
    },
    middle: {
        textAlign: 'center'
    },
    left: {
        textAlign: 'left'
    },
    right: {
        textAlign: 'right'
    },
    secondary: {
        color: theme.palette.text.secondary
    },
    base: {
        color: "white",
        margin: 0,
        borderRadius: 15,
        textTransform: 'capitalize',
        padding: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    defaultCard: {
        backgroundColor: '#0040ff',
    },
}))