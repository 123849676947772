import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const SidebarItem = ({ item, path, drawerOpen, textColor, firstLevel }) => {
    return (
        <ListItem
            disablePadding
            sx={{
                display: 'block',
                ml: drawerOpen && !firstLevel ? 2 : 0,
            }}
            component={Link}
            to={item.path}
        >
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: drawerOpen ? 'initial' : 'center',
                    pl: 2,
                    pr: 1
                }}
                selected={item.path === path}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: drawerOpen ? 2 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.title} sx={{ opacity: drawerOpen ? 1 : 0, color: textColor }} />
            </ListItemButton>
        </ListItem>
    )
};

export default SidebarItem;